.home {
  background: var(--primary-color);
  color: var(--text-color);
  padding: 0 20px;
  margin: 0 auto;
  max-width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}
.left {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.user_name {
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--secondary-color);
  margin-bottom: 10px;
}
.add_todo_container {
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid var(--secondary-color);
}
.add_todo_container form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
}

.add_todo_input {
  flex: 2;
  width: 100%;
  max-width: 600px;
  font-size: 1rem;
  font-weight: 400;
  color: #333;
  background-color: var(--secondary-color);
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid var(--secondary-color);
  margin-right: 10px;
}
.add_todo_btn {
  flex: 1;
  font-size: 1rem;
  font-weight: 400;
  background-color: green;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.sign_out_btn {
  font-size: 1rem;
  font-weight: 400;
  background-color: red;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.right {
  flex: 2;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.todos_list {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.todo_item {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}
.todo_item_checkbox i {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  font-size: 1.2rem;
}
.todo_item_checkbox i.completed {
  color: red;
}
.todo_item_title {
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--text-color);
  max-width: 200px;
  word-wrap: break-word;
}
.todo_item_title.completed {
  text-decoration: line-through;
  text-decoration-color: red;
  text-decoration-thickness: 2px;
}

.todo_item_date {
  font-size: 1rem;
  font-weight: 400;
  color: var(--secondary-color);
}
.todo_item_functions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.todo_item_update {
  font-size: 1rem;
  font-weight: 400;
  background-color: green;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin-right: 10px;
}
.todo_item_delete {
  font-size: 1rem;
  font-weight: 400;
  background-color: red;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

@media screen and (max-width: 800px) {
  .row {
    flex-direction: column;
  }
  .left {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
  }
  .right {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
  }
  .todos_list {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 0;
  }
}
